.top-nav-container {
    background-color: rgb(134, 130, 136);
    position: relative;
    overflow: hidden;
}

.top-nav-logo-container {
    margin: 30px 0 30px 45px;
    z-index: 1;
}

.top-nav-logo {
    height: 130px;
}

.top-nav-logo-years {
    color: white;
    margin: -23px 35px 0 0;
    font-size: 24px;
}

.top-nav-left-flower {
    position: absolute;
    bottom: -15px;
    left: -22px;
    z-index: 0;
}

.top-nav-right-flower {
    position: absolute;
    bottom: -5px;
    right: -12px;
    z-index: 0;
}

.top-nav-link {
    color: white;
    font-size: 24px;
    margin-left: 35px;
}

.top-nav-links {
    margin-right: 70px;
}

.top-nav-mobile-menu {
    display: none;
}

@media screen and (max-width: 1099px) {

    .top-nav-logo {
        height: 100px;
    }

    .top-nav-logo-years {
        margin: -23px 30px 0 0;
        font-size: 20px;
    }

    .top-nav-links {
        flex-direction: column;
        margin-right: 60px;
        justify-content: center !important;
    }

    .top-nav-link {
        font-size: 24px;
        margin: 3px 0 3px;
    }
}

@media screen and (max-width: 767px) {
    .top-nav-logo-container {
        margin: 5px 20px;
    }

    .top-nav-logo {
        height: 70px;
    }

    .top-nav-links {
        display: none !important;
    }

    .top-nav-left-flower {
        height: 70px;
        bottom: -6px;
        left: -10px;
    }

    .top-nav-right-flower {
        height: 80px;
        position: absolute;
        bottom: 0px;
        right: -3px;
        z-index: 0;
    }

    .top-nav-logo-years {
        color: white;
        margin: -18px 20px 5px 0;
        font-size: 20px;
    }

    .top-nav-mobile-menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        margin-right: 35px;
    }

    .top-nav-mobile-drawer {
        height: 100%;
        background-color: rgb(134, 130, 136);
        padding: 15px;
        font-family: Minion Pro;
    }

    .top-nav-link {
        font-size: 20px;
        margin-left: 0;
        margin-bottom: 10px;
    }

}