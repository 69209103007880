.gallery-image {
    height: 300px;
    width: 300px;
    background-color: gray;
    color: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.gallery-grid {
    margin-bottom: 350px;
}

.gallery-title {
    font-size: 24px;
}

@media screen and (max-width: 1099px) {
    .gallery-grid {
        margin-bottom: 230px;
    }
}

@media screen and (max-width: 767px) {
    .gallery-grid {
        margin-bottom: 30px;
    }
}