.footer-container {
    position: relative;
}

.footer-flower-bottom {
    position: absolute;
    bottom: 0px;
    left: -25px;
}

.footer-link {
    font-size: 26px;
}

.footer-divider {
    border-right: solid 1px rgb(130, 55, 148);
    height: 25px;
    margin: 0 30px;
}

.footer-links-container {
    padding-bottom: 30px;
}

@media screen and (max-width: 1099px) {
    .footer-link {
        font-size: 22px;
    }

    .footer-flower-bottom {
        height: 300px;
    }
}

@media screen and (max-width: 767px) {
    .footer-links-container {
        padding-bottom: 80px;
    }

    .footer-flower-bottom {
        height: 100px;
        left: 0;
    }

    .footer-link {
        font-size: 16px;
    }

    .footer-divider {
        border-right: solid 1px rgb(130, 55, 148);
        height: 10px;
        margin: 0 10px;
    }

}