.home-main-pic-container {
    width: 500px;
}

.home-main-pic {
    width: 100%;
    height: auto;
}

.home-obit {
    text-align: left;
    padding: 0 10px 0 40px;
    font-size: 18px;
}

.home-obit-name {
    font-size: 22px;
}

@media screen and (max-width: 1099px) {
    .home-obit-name {
        font-size: 20px;
    }

    .home-obit {
        font-size: 16px;
    }

    .home-main-pic-container {
        width: 300px;
    }
}

@media screen and (max-width: 767px) {
    .home-container {
        flex-direction: column;
    }

    .home-main-pic-container {
        width: 100%
    }

    .home-obit {
        padding: 0;
        margin-top: 20px;
    }
}