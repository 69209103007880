@font-face {
  font-family: 'Minion Pro';
  src: url("./fonts/MinionPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: 'Minion Pro';
  font-weight: bold;
  src: url("./fonts/MinionPro-Bold.otf") format("opentype");
}

.App {
  height: 100%;
  text-align: center;
  background-color: rgb(215, 215, 225);
  position: relative;
  font-family: Minion Pro;
}

.app-flower-bottom {
  position: absolute;
  bottom: -20px;
  left: -25px;
}

.section {
  padding: 40px;
}

.purple {
  color: rgb(130, 55, 148);
}

a {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .section {
    padding: 20px;
  }
}
