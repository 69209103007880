.service-video {
    height: 600px;
    width: 80%;
    /*background-color: gray;*/
    margin-bottom: 400px;
    color: white;
}

@media screen and (max-width: 1099px) {
    .service-video {
        height: 400px;
        margin-bottom: 250px;
    }
}

@media screen and (max-width: 767px) {
    .service-video {
        width: 100%;
        height: 200px;
        margin-bottom: 90px;
    }
}