.memories-list {
    margin-bottom: 400px;
    width: 100%;
}

.memories-share-card {
    margin-bottom: 30px;
    width: 600px;
}

.memories-share-card-header {
    font-size: 24px;
    color: rgb(130, 55, 148);
}

.memories-memory {
    background-color: rgb(130, 55, 148);
    color: white;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.memories-memory-inner {
    padding: 20px;
}

.memories-memory-message {
    font-size: 20px;
    white-space: pre-wrap;
}

.memories-memory-from {
    font-style: italic;
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
}

.memories-empty {
    margin-top: 30px;
    font-size: 24px;
}

@media screen and (max-width: 1099px) {
    .memories-list {
        margin-bottom: 270px;
    }
}

@media screen and (max-width: 767px) {
    .memories-list {
        margin-bottom: 20px;
    }

    .memories-share-card {
        width: 100%;
    }

    .memories-share-card-header {
        font-size: 20px;
    }

    .memories-empty {
        margin-top: 30px;
        font-size: 18px;
    }

    .memories-memory-message {
        font-size: 18px;
    }

    .memories-memory-from {
        font-size: 16px;
    }

    .memories-share-card-actions {
        flex-direction: column;
    }

    .memories-share-card-save {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
}